<template>
    <div class="th-paging">
        <nav aria-label="Kapitel-Navigation">
            <p v-if="this.pageNumber > this.maxPage">Bericht</p>
            <p v-else-if="showStepProgress">{{ textSteps }}</p>
            <ul>
                <li>
                    <button class="btn me-2 mb-2 btn-outline-primary" :disabled="isBackwardDisabled"
                            @click="$router.push(backwardPage)">
                        <i class="bi-chevron-left" aria-hidden="true"></i> zurück
                    </button>
                </li>
                <li>
                    <button class="btn me-2 mb-2 btn-outline-primary" :disabled="isForwardDisabled"
                            @click="$router.push(forwardPage)">
                        vor
                        <i class="bi bi-chevron-right" aria-hidden="true"></i>
                    </button>
                </li>
                <li>
                    <button class="btn me-2 mb-2 btn-outline-primary" :disabled="isResultsDisabled"
                            @click="$router.push(resultPage)">
                        <i class="bi bi-flag-fill" aria-hidden="true"></i> zum Bericht
                    </button>
                </li>
                <li>
                    <button class="btn me-2 mb-2 btn-outline-primary" @click="RESET">
                        <i class="bi bi-trash" aria-hidden="true"></i> Antworten löschen
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'Paging',
    props: {
        pageNumber: Number,
        countFilteredChapters: Number,
        showStepProgress: Boolean,
        maxPage: Number,
        requiredMissing: Boolean
    },
    computed: {
        ...mapGetters([
            'getTextSteps',
            'wasResultShown',
            'getUsedChapterIndices',
            'getStartChapterCounterAt'
        ]),
        isBackwardDisabled () {
            return this.pageNumber <= 1
        },
        isForwardDisabled () {
            return this.requiredMissing || this.pageNumber > this.maxPage
        },
        isResultsDisabled () {
            return !this.wasResultShown || this.pageNumber > this.maxPage
        },
        forwardPage () {
            let nextPageNumber = this.getUsedChapterIndices[this.pageNumber]
            return nextPageNumber
                ? { path: '/chapter/' + nextPageNumber, hash: '#skipTo' }
                : { path: '/results', hash: '#skipTo' }
        },
        backwardPage () {
            // as the pageNumber is incresed by one by default, we substract 2
            let prevPageNumber = this.getUsedChapterIndices[this.pageNumber - 2]

            // pageNumber 100001 is set per default on result page
            if (this.pageNumber === 100001) {
                prevPageNumber = this.getUsedChapterIndices[this.getUsedChapterIndices.length - 1]
            }
            return { path: '/chapter/' + prevPageNumber, hash: '#skipTo' }
        },
        resultPage () {
            return { path: '/results', hash: '#skipTo' }
        },
        textSteps () {
            // console.log('pageNumber ', this.pageNumber, 'maxPage ', this.maxPage, 'countFilteredChapters ', this.countFilteredChapters);
            if (this.pageNumber === 100001) {
                return 'Ergebnisse'
            } else {
                return this.getTextSteps
                    .replace("{x}", () => {
                        if(this.getStartChapterCounterAt > 0) {
                            return this.pageNumber - this.getStartChapterCounterAt + 1
                        } else {
                            return this.pageNumber
                        }
                    }
                    )
                    .replace('{y}',  () => {
                        if(this.getStartChapterCounterAt > 0) {
                            return this.countFilteredChapters - this.getStartChapterCounterAt + 1
                        } else {
                            return this.countFilteredChapters
                        }
                    })
            }
        }
    },
    methods: {
        ...mapActions([
            'RESET'
        ]),
        ...mapMutations([
            'setStepNumber'
        ]),
    }
}
</script>

<style scoped lang="scss">
ul:last-child:after {
    content: "";
    display: table;
    clear: both;
}

li {
    list-style: none;
    float: left;

}
</style>