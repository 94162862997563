<template>
  <div class="results" id="skipTo">
    <hr />
    <paging :pageNumber="100001" :maxPage="countChapters" />
    <hr />
    <!-- {{ JSON.stringify(getResultData)}} -->
    
    <h2>{{getResultTitle}}</h2>
    <div v-if="getResultIntro" v-html="getResultIntro"></div>
    <gearbox v-if="textQuestions" :version="4" class="gearbox-xs" :animation="isUpdating"></gearbox>
    <!-- {{ JSON.stringify(insight)}} -->

    <div>
      <h3>{{ insight.title }}</h3>
      <p>{{ textQuestions }}</p>
      <div v-if="insight.description" v-html="insight.description"></div>
      <div v-if="showListNeutral && listNeutral.length" class="alert d-flex" :class="'alert-' + getListNeutralStyle">
        <i class="alert-icon bi" :class="boxicon(getListNeutralStyle)"></i>
        <div class="alert-content">
          <div v-html="getListNeutralText"></div>
          <ul v-if="listNeutral">
            <li v-for="q in listNeutral" :key="q.question">
              <router-link :to="{ path: q.path, hash: q.hash }" v-html="q.question"></router-link>
              <p v-if="q.answer">{{q.answer}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <gearbox v-if="textPoints" :version="4" class="gearbox-xs" :animation="isUpdating"></gearbox>
    <!-- {{ JSON.stringify(result)}} -->
    <div>
      <h3>{{ result.title }}</h3>
      <p>{{ textPoints }}</p>
      <div v-if="result.description" v-html="result.description"></div>
      <div v-if="showListPoints && listPoints.length" class="alert d-flex" :class="'alert-' + getListPointsStyle">
        <i class="alert-icon bi" :class="boxicon(getListPointsStyle)"></i>
        <div class="alert-content">
          <div v-html="getListPointsText"></div>
          <ul v-if="listPoints">
            <li v-for="q in listPoints" :key="q.question">
              <router-link :to="{ path: q.path, hash: q.hash }" v-html="q.question"></router-link>
              <p v-if="q.answer">{{q.answer}}</p>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="showListNoPoints && listNoPoints.length" class="alert d-flex"
           :class="'alert-' + getListNoPointsStyle">
        <i class="alert-icon bi" :class="boxicon(getListNoPointsStyle)"></i>
        <div class="alert-content">
          <div v-html="getListNoPointsText"></div>
          <ul v-if="listNoPoints">
            <li v-for="q in listNoPoints" :key="q.question">
              <router-link :to="{ path: q.path, hash: q.hash }" v-html="q.question"></router-link>
              <p v-if="q.answer">{{q.answer}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <gearbox :version="2"></gearbox>

    <hr />
    <paging :pageNumber="100001" :maxPage="countChapters" />
    <hr />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Paging from '@/components/Paging.vue'
import Gearbox from '@/components/Gearbox.vue'

export default {
  name: 'results',
  components: {
    Paging,
    Gearbox
  },
  computed: {
    ...mapGetters([
      'isUpdating',
      'getResult',
      'getInsight',
      'getResultData',
      'getResultLists',
      'getResultTitle',
      'getResultIntro',
      'countChapters',
      'getTextQuestions',
      'getTextPoints',

      'showListNeutral',
      'getListNeutralText',
      'getListNeutralStyle',

      'showListPoints',
      'getListPointsText',
      'getListPointsStyle',

      'showListNoPoints',
      'getListNoPointsText',
      'getListNoPointsStyle',
    ]),
    debug () {
      return this.$store.state.checklist
    },
    insight () {
      return this.getInsight || {}
    },
    result () {
      return this.getResult || {}
    },
    textQuestions () {
      return this.getTextQuestions
        .replace("{x}", this.getResultData.nrOfAnswers)
        .replace('{y}', this.getResultData.nrOfQuestions)
    },
    textPoints () {
      return this.getTextPoints
        .replace("{x}", this.getResultData.currentPoints)
        .replace('{y}', this.getResultData.maxPoints)
    },
    listNeutral () {
      return this.getResultLists.listNeutral || []
    },
    listPoints () {
      return this.getResultLists.listPoints || []
    },
    listNoPoints () {
      return this.getResultLists.listNoPoints || []
    }
  },
  methods: {
    ...mapActions([
      'UPDATE_RESULT_LISTS',
      'CALCULATE_RESULT',
      'SET_RESULT'
    ]),
    boxicon: function (style) {
      switch (style) {
        case 'success':
          return 'bi-patch-check'
        case 'warning':
          return 'bi-exclamation-triangle'
        case 'danger':
          return 'bi-exclamation-octagon'
        default:
          return 'bi-info-circle'
      }
    }
  },
  mounted: function () {
    this.CALCULATE_RESULT()
    this.UPDATE_RESULT_LISTS()
    this.SET_RESULT(true)
  }
}
</script>

<style lang="scss">
</style>