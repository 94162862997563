<template>
  <progress-bar v-if="showProgressBar && showStepProgress" size="large" bar-color="#5d1265" :val="pageNumber - 1" :max="filteredChaptersCount - 1"></progress-bar>
  <div class="chapter" id="skipTo" tabindex="-1">
    <hr />
    <paging :showStepProgress="showStepProgress" :pageNumber="pageNumber" :maxPage="countChapters" :countFilteredChapters="filteredChaptersCount"
            :requiredMissing="requiredMissing" ref="pagingElement" />
    <hr />
    
    <span v-if="chapterDisabled" class="visually-hidden">"Dieser Bereich wurde aufgrund Ihrer bisher gegebenen Antworten deaktiviert."</span>

    <h2 class="visually-hidden">Fragen</h2>

    <h3>{{ chapterTitle }}</h3>
    <div v-html="chapterDescription"></div>

    <gearbox :version="4" class="gearbox-xs" :animation="isUpdating"></gearbox>

    <ol class="th-question-list">
      <li class="th-header th-header--3" :class="{'skip': question.isHint, 'disabled': question.disabled || chapterDisabled}" v-for="(question, questionIndex) in chapterQuestions"
          :key="chapterIndex + '_' + questionIndex" :aria-disabled="question.disabled || chapterDisabled">
        <question :chapterIndex="chapterIndex" :questionIndex="question.index" :questionRequired="question.required"
                  :chapterDisabled="chapterDisabled" />
      </li>
    </ol>

    <hr />
    <paging :showStepProgress="showStepProgress" :pageNumber="pageNumber" :maxPage="countChapters" :countFilteredChapters="filteredChaptersCount"
            :requiredMissing="requiredMissing" />
    <hr />
    <!-- <div class="debug">
      <ul>
        <li>{{ 'chapterObj: ' + JSON.stringify(chapterObj) }}</li>
        <li>{{ 'chapterTitle: ' + chapterTitle }}</li>
        <li>{{ 'chapterIndex, nr, pageNumber: ' + chapterIndex + ', ' + nr + ', ' + pageNumber }}</li>
        <li>{{ 'chapterIncludeIf: ' + chapterIncludeIf }}</li>
        <li>{{ 'chapterExcludeIf: ' + chapterExcludeIf }}</li>
        <li>{{ 'chapterDisabled: ' + chapterDisabled }}</li>
        <li>{{ 'chapterQuestions.length: ' + chapterQuestions.length }}</li>
        <li>{{ 'getDecisionArray: ' + getDecisionArray }}</li>
        <li>{{ 'requiredMissing: ' + requiredMissing }}</li>
        <li>{{ 'getUsedChapterIndices: ' + getUsedChapterIndices }}</li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Question from '@/components/Question.vue'
import Paging from '@/components/Paging.vue'
import Gearbox from '@/components/Gearbox.vue'
import ProgressBar from '@/components/Progress.vue'
// import StepProgress from '@/components/StepProgress.vue'

export default {
  name: 'chapter',
  components: {
    Question,
    Paging,
    Gearbox,
    // StepProgress,
    ProgressBar,
  },
  props: {
    nr: Number
  },
  // watch: {
  //   $route () {
  //     this.$nextTick(() => {
  //       // set keyboard focus to upper paging component
  //       if (this.$refs.pagingElement)
  //         this.$refs.pagingElement.$el.focus()
  //     })
  //   }
  // },
  updated () {
    var el = document.createElement("div");
    var id = "speak-" + Date.now();
    el.setAttribute("id", id);
    el.setAttribute("aria-live", "polite");
    el.classList.add("visually-hidden");
    document.body.appendChild(el);

    window.setTimeout(function () {
      document.getElementById(id).innerHTML = this.chapterTitle;
    }.bind(this), 200)

    window.setTimeout(function () {
      document.body.removeChild(document.getElementById(id));
    }, 1000)
  },
  computed: {
    ...mapGetters([
      'isUpdating',
      'getChapter',
      'getQuestions',
      'getChapterTitles',
      'countFilteredChapters',
      'countChapters',
      'getDecisionArray',
      'getUsedChapterIndices',
      'getStartChapterCounterAt',
      'showProgressBar'
    ]),
    chapterIndex () {
      if (this.getChapter(this.nr).title) {
        return this.nr
      }
      if (this.nr > this.countChapters) {
        return this.countChapters
      }
      return 0
    },
    pageNumber () {
      return this.getUsedChapterIndices.indexOf(this.chapterIndex) + 1
    },
    showStepProgress () {
      return this.pageNumber >= this.getStartChapterCounterAt
    },
    chapterTitles () {
      return this.getChapterTitles.slice(this.getStartChapterCounterAt - 1)
    },
    filteredChaptersCount () {
      return this.countFilteredChapters
    },
    chapterIncludeIf () {
      return this.getChapter(this.chapterIndex).include_if
    },
    chapterExcludeIf () {
      return this.getChapter(this.chapterIndex).exclude_if
    },
    chapterTitle () {
      return this.getChapter(this.chapterIndex).title
    },
    chapterDescription () {
      return this.getChapter(this.chapterIndex).description
    },
    chapterQuestions () {
      return this.getQuestions(this.chapterIndex)
    },
    requiredMissing () {
      return this.getQuestions(this.chapterIndex).some((question) => question.required && question.answer === null && !question.disabled && !this.chapterDisabled)
    },
    chapterDisabled () {
      return this.getChapter(this.chapterIndex).disabled
    },
    chapterObj () {
      return this.getChapter(this.chapterIndex)
    }

  }
}
</script>

<style lang="scss">
li p {
  text-align: left;
}

.privacy {
  margin: 1em;
  padding: 0.75em 1em 0 1em;
  border: 1px solid #e0e0e0;
  background-color: #f0f0f0;
}

.text-right {
  text-align: right;
}

.debug {
  position: fixed;
  background: whitesmoke;
  right: 0;
  bottom: 0;
}
</style>