<template>
    <!-- app install btn https://levelup.gitconnected.com/vue-pwa-example-298a8ea953c9 -->
    <div class="installbutton" :style="{ 'display': installBtn }">
      <button
        type="button"
        class="btn btn-outline-light"
        @click="installer()"
      > als App installieren</button>
    </div>
</template>

<script>
export default {
    name: 'InstallApp',
    data() {
        return {
            installBtn: "none",
            installer: undefined
        };
    },
    created() {
        let installPrompt;

        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            installPrompt = e;
            this.installBtn = "block";
        });

        this.installer = () => {
            this.installBtn = "none";
            installPrompt.prompt();
            installPrompt.userChoice.then(result => {
                if (result.outcome === "accepted") {
                    console.log("Install accepted!")
                } else {
                    console.log("Install denied!")
                }
            });
        };
    }
}
</script>

<style lang="scss" scoped>
.installbutton {
    margin: -1.25rem 0 .25rem;
    text-align: center;
}
</style>