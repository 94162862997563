import { createRouter, createWebHistory, isNavigationFailure } from 'vue-router'
import store from '@/store/index.js'
import Chapter from '../views/Chapter.vue'
import Results from '../views/Results.vue'
import NotFound from '../views/NotFound.vue'

/* 
 * TODO: Es sollte noch abgeklärt werden, ob es einen besseren 
 * Weg gibt, einen Breadcrumb zu erstellen, ohne das manuelle 
 * Angeben der Elternelemente. Eventuell durch Nested Routes 
 * (vgl. https://router.vuejs.org/guide/essentials/nested-routes.html)
 */
const routes = [
  {
    path: '/',
    redirect: '/chapter/0',
  },
  {
    path: '/chapter/:nr?',
    name: 'chapter',
    component: Chapter,
    props (route) {
      const props = { ...route.params }
      // casting to number
      props.nr = +props.nr
      return props || 0
    }
  },
  {
    path: '/results',
    name: 'results',
    component: Results,
    meta: {
      title: 'Bericht'
    }
    // Lazy-loading
    // component: () => import(/* webpackChunkName: "Results" */ '../views/Results.vue')
  },
  { path: '/:pathMatch(.*)', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior: function (to, from, savedPosition) {
    // let position = {}
    if (to.hash) {

      return {
        el: to.hash,
        top: +10
      }
      // return new Promise((resolve) => {

      //   setTimeout(() => {
      //     resolve({
      //       el: to.hash,
      //       top: +10 
      //       // offset: { x: 0, y: 10 }
      //     })
      //   }, 1000)
      // })
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
    // eslint-disable-next-line no-unused-vars
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(position)
    //   }, 10)
    // })
  }
})
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  if (to.hash) {
    waitForElm(to.hash).then((elm) => {
      elm.focus()
    })
  }
})

router.afterEach((toRoute, fromRoute, failure) => {
  if (!isNavigationFailure(failure)) {

    // announce title to screenreaders
    let el = document.createElement('div');
    let id = 'speak-' + Date.now();
    el.setAttribute('id', id);
    el.setAttribute('aria-live', 'polite');
    el.classList.add('visually-hidden', 'speak');
    document.body.appendChild(el);
    window.setTimeout(function () {
      // read the document title without the th40 name suffix
      document.getElementById(id).innerHTML = window.document.title.replace(' - Teilhabe 4.0', '');
    }.bind(this), 100)

    window.setTimeout(function () {
      let spokenText = document.getElementsByClassName('speak')
      for (let i = 0; i < spokenText.length; i++) {
        const element = spokenText[i];

        // console.log(element);
        document.body.removeChild(element)
      }
    }, 1000)
  }
})

// eslint-disable-next-line no-unused-vars
router.beforeResolve(function (to, from, next) {
  if (store.state.checklist && store.state.checklist.title) {
    let title = store.state.checklist.title
    if (to.name !== 'results') {
      if (to.params && to.params.nr) {
        title += ' - ' + (parseInt(to.params.nr) + 1)
      }
    } else {
      title += ' - ' + to.meta.title
    }
    title += ' - Teilhabe 4.0'
    window.document.title = title
  }
  next();
})

function waitForElm (selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    // eslint-disable-next-line no-unused-vars
    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,

      subtree: true
    });
  });
}

export default router
