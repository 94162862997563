<template>
  <div class="app">
    <header class="app_header">
      <InstallApp />
      <div class="skip-link-container">
        <a
          v-if="isChapter"
          href="#skipTo"
          ref="skipLink"
          class="visually-hidden-focusable"
        >Zu den Fragen springen</a>
        <a
          v-if="isResults"
          href="#skipTo"
          ref="skipLink"
          class="visually-hidden-focusable"
        >Zum Bericht springen</a>
      </div>
      <div class="container">
        <div class="app_header_logo">
          <router-link to="/">
            <img
              class="logo"
              :src="getLogoBase64"
              :alt="getAlt"
              :width="getLogoWidth"
              :height="getLogoHeight"
            />
          </router-link>
        </div>
        <h1 class="app_header_title">{{ getTitle }}</h1>
        <p class="app_header_subtitle">{{ getSubtitle }}</p>
      </div>
    </header>
    <main
      class="app_content"
      :aria-busy="isLoading || isUpdating"
    >
      <div class="container">
        <div v-html="getDescription"></div>

        <div
          v-if="alertNoteVisible"
          class="alert alert-warning d-flex"
          role="alert"
        >
          <i class="alert-icon bi bi-exclamation-triangle"></i>
          <div
            class="alert-content"
            v-html="note"
          >
          </div>
          <button
            type="button"
            class="alert-close"
            aria-label="Hinweis schließen"
            title="schließen"
            @click="closeNote"
          ></button>
        </div>

        <div
          v-if="alertPrivacyVisible"
          class="alert alert-secondary d-flex"
          role="alert"
        >
          <i class="alert-icon bi bi-info-circle"></i>
          <div
            class="alert-content"
            v-html="privacy"
          >
          </div>
          <button
            type="button"
            class="alert-close"
            aria-label="Hinweis zum Datenschutz schließen"
            title="schließen"
            @click="closePrivacy"
          ></button>
        </div>

        <gearbox
          :version="1"
          :animation="isLoading"
        ></gearbox>

        <router-view />
      </div>
    </main>
    <footer class="app_footer">
      <div
        class="container"
        role="navigation"
      >
        <h2 class="visually-hidden">Service-Navigation</h2>
        <ul>
          <li v-if="getLinkImprint">
            <a :href="getLinkImprint">Impressum</a>
          </li>
          <li v-if="getLinkPrivacy">
            <a :href="getLinkPrivacy">Datenschutz</a>
          </li>
          <li v-if="getLinkAccessibility">
            <a :href="getLinkAccessibility">Barrierefreiheit</a>
          </li>
          <li v-if="getLinkAccessibilityContact">
            <a :href="getLinkAccessibilityContact">Barriere melden</a>
          </li>
          <li v-if="debug">
            <a
              class="th-nav_link th-nav_link-debug"
              :href="webservice"
            >data</a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Gearbox from '@/components/Gearbox.vue'
import InstallApp from '@/components/InstallApp.vue'

export default {
  name: 'app',
  components: {
    Gearbox,
    InstallApp
  },
  created: function () {
    this.$store.dispatch('INIT_DATA')
    // document.addEventListener('swUpdated', this.$store.dispatch('RESET'), { once: true })
  },
  mounted () {
    document.addEventListener('swUpdated', () => {
      this.$store.dispatch('RESET')
  }, { once: true })
},
beforeUnmount() {
  document.removeEventListener('swUpdated', this.$store.dispatch('RESET'), { once: true })
},
data() {
  return {
    alertPrivacy: true,
    alertNote: true
  }
},
computed: {
    ...mapGetters([
  'isLoading',
  'isUpdating',
  'getTitle',
  'getSubtitle',
  'getLogo',
  'getLogoWidth',
  'getLogoHeight',
  'getLogoBase64',
  'getAlt',
  'getLink',
  'getDescription',
  'getPrivacy',
  'getNote',
  'getLinkPrivacy',
  'getLinkImprint',
  'getLinkPrivacy',
  'getLinkAccessibility',
  'getLinkAccessibilityContact',
]),
    webservice() {
    return this.$store.state.webservice
  },
  privacy() {
    return this.getPrivacy
      + "<p class='text-right'><a href='"
      + this.getLinkPrivacy
      + "'>mehr zum Datenschutz</a></p>"
  },
  note() {
    return this.getNote
  },
  debug() {
    return this.$store.state.debug
  },
  isChapter() {
    return this.$route.name == 'chapter';
  },
  isResults() {
    return this.$route.name == 'results';
  },
  alertNoteVisible() {
    return this.alertNote && this.note;
  },
  alertPrivacyVisible() {
    return this.alertPrivacy && this.privacy;
  }
},
methods: {
  closePrivacy() {
    this.alertPrivacy = false;
  },
  closeNote() {
    this.alertNote = false;
  }
},
}
</script>

<style lang="scss">@import "@/scss/style.scss";</style>
