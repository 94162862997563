<template>
    <div class="th-question clearfix" :id="'question' + questionIndex" tabindex="-1">
        <div :id="'label_' + questionIndex">
          <h4 v-html="(questionObj.disabled ? '<span class=&quot;visually-hidden&quot;>(Deaktiviert)</span> ': '') + questionObj.question + (required ? '<span class=&quot;visually-hidden&quot;>(Pflichtfeld)</span>': '')"></h4>
          <div v-html="questionObj.help"></div>
        </div>
        <div v-if="questionObj.answerOptions">
            <div v-if="!questionObj.isHint" role="group" :aria-labelledby="'label_' + questionIndex">
                <radio-button
                    v-for="(answerObj, i) in questionObj.answerOptions"
                    :key="chapterIndex + '_' + questionIndex + ' ' + i"
                    v-model="answer"
                    :value="(answerObj.answerDecision || i)"
                    :id="'q' + chapterIndex + '_' + questionIndex + '_' + i"
                    :name="chapterIndex + '_' + questionIndex"
                    size="md"
                    flavor="ring"
                    :required="questionRequired"
                    :disabled="questionObj.disabled || chapterDisabled"
                >{{ answerObj.label }}</radio-button>
            </div>
        </div>

        <div v-else>
            <div v-if="!questionObj.isHint" role="group" :aria-labelledby="'label_' + questionIndex">
                <radio-button
                    v-for="(answerObj, i) in answersObj"
                    :key="chapterIndex + '_' + questionIndex + ' ' + i"
                    v-model="answer"
                    :value="i"
                    :id="'q' + chapterIndex + '_' + questionIndex + '_' + i"
                    :name="chapterIndex + '_' + questionIndex"
                    size="md"
                    flavor="ring"
                    :required="questionRequired"
                    :disabled="questionObj.disabled || chapterDisabled"
                >
                    {{answerObj.answer}}
                </radio-button>
            </div>
        </div>
        <div aria-hidden="true" style="margin-left:-40px; clear:both;">
            <gearbox :version="4" class="gearbox-xs" :animation="isUpdating"></gearbox>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Gearbox from '@/components/Gearbox.vue'
import RadioButton from '@/components/RadioButton.vue'

export default {
    name: 'Question',
    props: {
        chapterIndex: Number,
        questionIndex: Number,
        questionRequired: Boolean,
        chapterDisabled: Boolean
    },
    components: {
        Gearbox,
        RadioButton
    },
    data: function () {
        return {
            test: 0
        }
    },
    computed: {
        ...mapGetters([
            'isUpdating',
            'getAnswers',
            'getQuestion',
            'getDecisionArray'
        ]),
        questionObj () {
            return this.getQuestion(this.chapterIndex, this.questionIndex)
        },
        required () {
            return this.questionRequired & !this.questionObj.disabled
        },
        decisionArray () {
            return this.getDecisionArray
        },
        answer: {
            get () {
                return this.questionObj.answer
            },
            set (value) {
                this.SET_ANSWER({ c: this.chapterIndex, q: this.questionIndex, v: value })
            }
        },
        answersObj () {
            return this.getAnswers
        }
    },
    methods: {
        ...mapActions([
            'SET_ANSWER'
        ]),
    }
}
</script>

<style scoped lang="scss">
</style>